export const subscribeToTowerHunt = {
  order: 0,
  name: "Subscribe to Tower Hunt",
  subcategory: "Manage your account",
  markdown: `# Subscribe to Tower Hunt

  Tower Hunt's {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}pay-as-you-go pricing{% /inlineRouterLink %} looks first to your {% inlineRouterLink %}credit balance{% /inlineRouterLink %} and then to an authorized payment method. Read on to learn how to subscribe to Tower Hunt.

  {% tablessContainer %}
  1. {% inlineRouterLink articleId="sign-in-to-tower-hunt" %}Sign in to Tower Hunt{% /inlineRouterLink %}.
  2. Click your initials in the upper-right and choose **My Account** from the menu.
  3. Under **Spending on Tower Hunt**, enter your password to verify that it's you and click **Submit**.
  4. Under **Your Tower Hunt Plan**, select the **Hunter** plan and click **Save**.
  5. Choose or {% inlineRouterLink articleId="add-a-payment-method" %}add a payment method{% /inlineRouterLink %}.
  6. Review the authorization request and click **Authorize** when ready.
  7. Enjoy using Tower Hunt!
  {% /tablessContainer %}`,
};
